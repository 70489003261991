exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-audio-streaming-js": () => import("./../../../src/pages/audio-streaming.js" /* webpackChunkName: "component---src-pages-audio-streaming-js" */),
  "component---src-pages-cualesmiip-js": () => import("./../../../src/pages/cualesmiip.js" /* webpackChunkName: "component---src-pages-cualesmiip-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politicas-de-privacidad-js": () => import("./../../../src/pages/politicas-de-privacidad.js" /* webpackChunkName: "component---src-pages-politicas-de-privacidad-js" */),
  "component---src-pages-reseller-hosting-js": () => import("./../../../src/pages/reseller-hosting.js" /* webpackChunkName: "component---src-pages-reseller-hosting-js" */),
  "component---src-pages-reseller-streaming-js": () => import("./../../../src/pages/reseller-streaming.js" /* webpackChunkName: "component---src-pages-reseller-streaming-js" */),
  "component---src-pages-servidores-dedicados-js": () => import("./../../../src/pages/servidores-dedicados.js" /* webpackChunkName: "component---src-pages-servidores-dedicados-js" */),
  "component---src-pages-servidores-vps-js": () => import("./../../../src/pages/servidores-vps.js" /* webpackChunkName: "component---src-pages-servidores-vps-js" */),
  "component---src-pages-terminos-condiciones-js": () => import("./../../../src/pages/terminos-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-condiciones-js" */),
  "component---src-pages-web-hosting-js": () => import("./../../../src/pages/web-hosting.js" /* webpackChunkName: "component---src-pages-web-hosting-js" */)
}

